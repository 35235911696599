import classNames from "classnames";
import { Link } from "gatsby";
import React from "react";

import * as styles from "./Logo.module.scss";

const Logo = ({ title, location = "London", animate = true }) => {
  return (
    <Link
      to="/"
      className={classNames(styles.logo, { [styles.animate]: animate })}
    >
      <span className={classNames(styles.title, "active")}>{title}</span>
      <span className={styles.line} />
      <span className={classNames(styles.location, "text")}>{location}</span>
    </Link>
  );
};

export { Logo };
