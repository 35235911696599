import React, { useState } from "react";
import classNames from "classnames";

import { formatTime } from "../../../js/utils";
import { useSiteContext } from "../../SiteContext";
import { TimePicker } from "../../Common/TimePicker";
import { Logo } from "../../Common/Logo";

import * as styles from "./Header.module.scss";
import { Icon } from "../../Common/Icon";
import { useMeta } from "../../../hooks/useMeta";

const Header = ({ title = "Beer Miles" }) => {
  const { usedTime, setMenuOpen, setCustomTime } = useSiteContext();
  const { location } = useMeta();

  const [pickerOpen, setPickerOpen] = useState(false);

  return (
    <header className={styles.header}>
      <div>
        <button
          onClick={() => setMenuOpen(true)}
          className={classNames("btn-reset", "text-button", styles.toggle)}
          aria-label="Menu Button"
        >
          <Icon name="menu" className={styles.toggleIcon} />
          <span className={styles.toggleText}>Menu</span>
        </button>
      </div>

      <div className={styles.middle}>
        <Logo title={title} location={location} />
      </div>

      <div className={styles.right}>
        {usedTime > 0 && (
          <button
            onClick={() => setPickerOpen(true)}
            className={classNames("btn-reset", styles.time)}
          >
            <span className={classNames(styles.timeDay, "text-weak")}>
              {formatTime(usedTime, "local-day")}
            </span>
            <span className={styles.timeTime}>
              {formatTime(usedTime, "local-time")}
            </span>
          </button>
        )}
      </div>

      {pickerOpen && (
        <TimePicker
          className={styles.picker}
          currentTime={usedTime}
          onChange={setCustomTime}
          onReset={() => setCustomTime(null)}
          onClose={() => setPickerOpen(false)}
        />
      )}
    </header>
  );
};

export { Header };
