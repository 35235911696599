import React, { useMemo } from "react";
import classNames from "classnames";

import * as styles from "./Icon.module.scss";

import {
  CheckCircle,
  XCircle,
  X,
  Check,
  NavigationArrow,
  MapPin,
  PersonSimpleWalk,
  Train,
  Heart,
  Compass,
  ArrowLeft,
  Bus,
  Lightning,
  Confetti,
  Clock,
  ArrowsOut,
  ArrowsIn,
  ShareNetwork,
  List,
  MapTrifold,
  SmileySad,
} from "@phosphor-icons/react";

export const IconMap = {
  pin: MapPin,
  train: Train,
  bus: Bus,
  walk: PersonSimpleWalk,
  checkmark: Check,
  "check-circle": CheckCircle,
  "x-circle": XCircle,
  cross: X,
  heart: Heart,
  location: Compass,
  heading: NavigationArrow,
  arrow: ArrowLeft,
  lightning: Lightning,
  party: Confetti,
  clock: Clock,
  expand: ArrowsOut,
  shrink: ArrowsIn,
  share: ShareNetwork,
  menu: List,
  map: MapTrifold,
  sad: SmileySad,
};

const Icon = ({ className, name, rotate = 0, animate = false, ...rest }) => {
  const cls = classNames(styles.icon, className, {
    [styles.animate]: animate,
  });

  const IconEl = useMemo(() => {
    if (!IconMap[name]) {
      console.log(`No icon ${name}`);

      return IconMap["heart"];
    }

    return IconMap[name];
  }, [name]);

  return (
    <span className={cls} style={{ "--rotate": `${rotate}deg` }}>
      <IconEl {...rest} />
    </span>
  );
};

export { Icon };
