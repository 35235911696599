import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "@reach/router";

const defaultContext = {
  customTime: null,
  setCustomTime: () => false,
  currentTime: null,
  usedTime: null,
  color: "red",
  setColor: () => false,
  menuOpen: false,
  setMenuOpen: () => false,
};

const SiteContext = createContext(defaultContext);

const SiteProvider = ({ children }) => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [customTime, setCustomTime] = useState(null);
  const [currentTime, setCurrentTime] = useState(null);
  const [color, setColor] = useState("red");
  const lastColor = useRef(color);

  const usedTime = useMemo(() => {
    return customTime || currentTime || 0;
  }, [currentTime, customTime]);

  useEffect(() => {
    if (window.plausible && customTime) {
      window.plausible("Time Change", {
        props: { time: customTime },
      });
    }
  }, [customTime]);

  useEffect(() => {
    setCurrentTime(new Date());

    let intid = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => clearInterval(intid);
  }, []);

  useEffect(() => {
    setMenuOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    document.body.classList.add(color);
    document.body.classList.remove(lastColor.current);

    lastColor.current = color;
  }, [color]);

  const value = {
    customTime,
    setCustomTime,
    currentTime,
    usedTime,
    color,
    setColor,
    menuOpen,
    setMenuOpen,
  };

  return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>;
};

const useSiteContext = () => useContext(SiteContext);

export { SiteProvider, useSiteContext };
