import React, { useCallback, useEffect, useMemo, useRef } from "react";
import classNames from "classnames";

import * as styles from "./TimePicker.module.scss";
import { format, setDay, setHours, setMinutes } from "date-fns";
import { Tag } from "../Tag";
import { clickOutside } from "../../../js/utils";

const DAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const HOURS = new Array(24)
  .fill(0)
  .map((_, idx) => idx.toString().padStart(2, "0"));
const MINS = new Array(60)
  .fill(0)
  .map((_, idx) => idx.toString().padStart(2, "0"));

const GROUP = [DAYS, HOURS, MINS];

const TimePicker = ({ className, currentTime, onChange, onReset, onClose }) => {
  const pickerRef = useRef(null);

  const currentTimeDate = useMemo(() => {
    return new Date(currentTime);
  }, [currentTime]);

  const currentBlocks = useMemo(() => {
    return format(currentTimeDate, "EEE HH mm").split(" ");
  }, [currentTimeDate]);

  useEffect(() => {
    currentBlocks.forEach((item, idx) => {
      const el = pickerRef.current?.querySelector(`.btn-${idx}-${item}`);

      const mid = el.parentElement.getBoundingClientRect().height / 2;

      el.parentElement.scrollTo({
        top: el.offsetTop - mid,
        behavior: "smooth",
      });
    });
  }, [currentBlocks]);

  useEffect(() => {
    return clickOutside(pickerRef.current, () => {
      onClose();
    });
  }, [onClose]);

  const onUpdate = useCallback(
    (val, groupIdx) => {
      switch (groupIdx) {
        case 0: // day
          onChange(setDay(currentTimeDate, (DAYS.indexOf(val) + 1) % 7));
          break;
        case 1: // hour
          onChange(setHours(currentTimeDate, parseInt(val)));
          break;
        case 2: // min
          onChange(setMinutes(currentTimeDate, parseInt(val)));
          break;
        default:
          break;
      }
    },
    [currentTimeDate, onChange]
  );

  return (
    <div ref={pickerRef} className={classNames(styles.wrapper, className)}>
      <div className={styles.columns}>
        {GROUP.map((group, groupIdx) => (
          <div key={`group-${groupIdx}`} className={styles.column}>
            {group.map((item) => (
              <button
                onClick={() => onUpdate(item, groupIdx)}
                key={`${groupIdx}-${item}`}
                className={classNames("btn-reset", `btn-${groupIdx}-${item}`, {
                  [styles.active]: item === currentBlocks[groupIdx],
                })}
              >
                {item}
              </button>
            ))}
          </div>
        ))}
      </div>

      <div className={styles.bottom}>
        <Tag theme="alt" size="small" icon="cross" onClick={onClose} />

        <Tag
          onClick={onReset}
          size="small"
          className={classNames(styles.sub, "btn-reset")}
        >
          Now
        </Tag>
      </div>
    </div>
  );
};

export { TimePicker };
