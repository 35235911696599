import React from "react";
import { Link } from "gatsby";

const InternalExternalLink = ({ to, children, ...props }) => {
  if (to.startsWith("http") || to.startsWith("mailto")) {
    return (
      <a href={to} target="_blank" rel="noreferrer noopener" {...props}>
        {children}
      </a>
    );
  }

  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};

export { InternalExternalLink };
