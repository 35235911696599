import classNames from "classnames";
import React, { useMemo } from "react";
import { Icon } from "../Icon";
import { InternalExternalLink } from "../InternalExternalLink";

import * as styles from "./Tag.module.scss";

const Tag = ({
  children,
  to,
  onClick,
  icon,
  size,
  reverse,
  theme = "standard",
  iconProps = {},
  className,
  ...rest
}) => {
  const El = useMemo(() => {
    if (to) {
      return InternalExternalLink;
    }

    if (onClick) {
      return "button";
    }

    return "span";
  }, [to, onClick]);

  const props = useMemo(() => {
    if (to) {
      return {
        to,
      };
    }

    if (onClick) {
      return {
        onClick,
        ...rest,
      };
    }

    return {};
  }, [to, onClick, rest]);

  return (
    <El
      {...props}
      className={classNames(
        styles.tag,
        {
          [styles.iconOnly]: !children && icon,
          [styles[size]]: true,
          [styles[theme]]: true,
          [styles.reverse]: reverse,
        },
        className
      )}
    >
      {children}
      {icon && <Icon className={styles.icon} name={icon} {...iconProps} />}
    </El>
  );
};

export { Tag };
