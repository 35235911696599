import { graphql, useStaticQuery } from "gatsby";
import { useMemo } from "react";

export const useMeta = () => {
  const data = useStaticQuery(graphql`
    query MetaQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "meta" } }) {
        frontmatter {
          title
          description
          social_image {
            publicURL
          }
          social_image_twitter {
            publicURL
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
          parentUrl
          subsite
          location
        }
      }
      routes: allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "route-page" } } }
        sort: { frontmatter: { order: ASC } }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title: short_title
            }
          }
        }
      }
    }
  `);

  const obj = useMemo(() => {
    return {
      ...data.markdownRemark.frontmatter,
      ...data.site.siteMetadata,
      image: data.markdownRemark.frontmatter.social_image.publicURL,
      twitterImage:
        data.markdownRemark.frontmatter.social_image_twitter.publicURL,
      isSingleton: data.routes.edges.length === 1,
      isSubsite: data.site.siteMetadata.subsite || true,
      prefix: (str) => {
        if (str.startsWith("http")) {
          return str;
        }

        return [
          data.site.siteMetadata.siteUrl,
          str.startsWith("/") ? str.slice(1) : str,
        ].join("/");
      },
      parentPrefix: (str) => {
        if (str.startsWith("http")) {
          return str;
        }

        return [
          data.site.siteMetadata.subsite || true
            ? data.site.siteMetadata.parentUrl
            : data.site.siteMetadata.siteUrl,
          str.startsWith("/") ? str.slice(1) : str,
        ].join("/");
      },
    };
  }, [data]);

  return obj;
};
