import React, { useEffect } from "react";

import "normalize.css";
import "../../../styles/global.scss";

// import * as styles from "./Layout.module.scss";
import { Header } from "../Header";
import { SiteProvider } from "../../SiteContext";
import { Menu } from "../Menu";

const Layout = ({ children }) => {
  useEffect(() => {
    document.documentElement.classList.add("settled");
  }, []);

  return (
    <SiteProvider>
      <Header />

      <Menu />

      {children}
    </SiteProvider>
  );
};

export { Layout };
