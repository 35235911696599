// extracted by mini-css-extract-plugin
export var alt = "Tag-module--alt--89887";
export var disabled = "Tag-module--disabled--13631";
export var error = "Tag-module--error--21845";
export var icon = "Tag-module--icon--7dea9";
export var iconOnly = "Tag-module--icon-only--e1582";
export var medium = "Tag-module--medium--74318";
export var neutral = "Tag-module--neutral--7872b";
export var red = "Tag-module--red--608ee";
export var reverse = "Tag-module--reverse--9cad0";
export var small = "Tag-module--small--fa83f";
export var success = "Tag-module--success--a5ca9";
export var tag = "Tag-module--tag--abebc";
export var text = "Tag-module--text--2e13e";