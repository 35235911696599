import * as React from "react";

import { Layout } from "./src/components/UI/Layout";
import { persistValue, removePersistedValue } from "./src/js/utils";

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const onPreRouteUpdate = ({ prevLocation, location }) => {
  const prev = location ? location.pathname : "";

  if (prev.startsWith("/route/") || prev.startsWith("/directory")) {
    persistValue("from-route", prev);
  } else {
    removePersistedValue("from-route");
  }
};
