// extracted by mini-css-extract-plugin
export var linkList = "Menu-module--link-list--82117";
export var lower = "Menu-module--lower--3557a";
export var menu = "Menu-module--menu--cf335";
export var open = "Menu-module--open--ba29f";
export var overlay = "Menu-module--overlay--d1159";
export var sectionTitle = "Menu-module--section-title--6b79a";
export var show = "Menu-module--show--04ead";
export var small = "Menu-module--small--daacb";
export var sub = "Menu-module--sub--fdcc3";
export var top = "Menu-module--top--b20fa";
export var transition = "Menu-module--transition--56efb";
export var upper = "Menu-module--upper--ec984";